<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <n-search-container
      :active="true"
      :show-header="true"
      @search="search"
      @reset="reset"
      show-search
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>
    <b-card>
      <b-card-body no-body class="mb-0" v-if="!loading">
        <b-row>
          <b-col md="3" v-for="(value, key) in data" :key="key">
            <b-card>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-success">
                    <feather-icon size="24" icon="DollarSignIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ parseFloat(value).toFixed(2) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t('report.' + key) }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="d-flex justify-content-center align-items-center mb-1" v-else>
        <b-spinner label="loading" type="grow" />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import searchInputs from './searchInput'

const ReportRepository = Repository.get('report')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    NSearchContainer,
    NSearchInput,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      },
      initData: {
        banners: [],
      },
      params: {
        date: new Date(),
        year: new Date().getFullYear(),
      },
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    back() {
      this.$router.back()
    },
    getData() {
      this.loading = true
      this.params.year = this.params.date.getFullYear()
      const params = {
        ...this.params,
      }
      ReportRepository.index(params)
        .then((response) => {
          this.data = response.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    search(searchText) {
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.$refs.search.reset()
      this.updateQuerySting()
      this.getData()
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
  },
  setup() {
    const searchFields = [...searchInputs]
    return {
      searchFields,
    }
  },
}
</script>
